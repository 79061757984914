import { dropTime } from "./constants";

document.addEventListener("DOMContentLoaded", afterDOMContentLoaded);

function afterDOMContentLoaded() {
  startTimer();
}

const dropDateTime = new Date(dropTime);

let hasTimerStarted = false;

export function startTimer() {
  const countdownTextEl = document.getElementById("countdown-text");
  const mintNowEl = document.getElementById("mint-now-text");

  const daysEl = document.getElementById("countdown-days");
  const hoursEl = document.getElementById("countdown-hours");
  const minutesEl = document.getElementById("countdown-minutes");
  const secondsEl = document.getElementById("countdown-seconds");
  const secondsContEl = document.getElementById("countdown-seconds-cont");

  if (!hasTimerStarted) {
    hasTimerStarted = true;
    console.log(`Countdown until ${dropDateTime.toString()}`);
    setTime();
    setInterval(setTime, 1000);
  }

  function setTime() {
    let diff = dropDateTime.getTime() - Date.now();
    let showSeconds = false;

    if (diff < 0) {
      countdownTextEl.setAttribute("hidden", "true");
      mintNowEl.removeAttribute("hidden");
      return;
    }

    if (diff < 60000) {
      showSeconds = true;
    }

    // seconds
    diff = Math.floor(diff / 1000);
    if (showSeconds) {
      secondsContEl.removeAttribute("hidden");
      secondsEl.innerText = (diff % 60).toString();
    }

    // minutes
    diff = Math.floor(diff / 60);
    minutesEl.innerText = (diff % 60).toString();

    // hours
    diff = Math.floor(diff / 60);
    hoursEl.innerText = (diff % 24).toString();

    // days
    diff = Math.floor(diff / 24);
    daysEl.innerText = diff.toString();
  }
}
